<template>
  <div class="nav-bar">
    <div class="grid-table-cell-wide nav-bar-top" style="padding-top: 3%;">
      <div class="bottom-margin">
        <button @click="navToQuestionList"
          :class="['btn-fixed-width', { 'green-overlay': isQuestionListRoute }]">{{ui_string.navbar_button_question_list}}</button>
      </div>
      <div class="bottom-margin">
        <button @click="navToCallHistory"
          :class="['btn-fixed-width', { 'green-overlay': isCallListRoute }]">{{ui_string.navbar_button_call_list}}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useSettingsStore } from "../store";
import TheSelector from "./TheSelector.vue";
import { storeToRefs } from 'pinia';

const settings = useSettingsStore();
const { ui_string } = storeToRefs(settings);

const router = useRouter();
const route = useRoute();

const isQuestionMakeRoute = computed(() => route.path === "/question_make");
const isQuestionListRoute = computed(() => route.path === "/question_list" || route.path.startsWith("/question_edit"));
const isCallListRoute = computed(() => route.path.startsWith("/call_history"));

const navToQuestionMake = () => {
  router.push("/question_make");
};

const navToQuestionList = () => {
  router.push("/question_list");
};

const navToCallHistory = () => {
  router.push("/call_history_list");
};


</script>

<style scoped>
.nav-bar {
  width: 100%;
  background-image: url("/metal-seamless-bright.png");
  /* box-shadow: 1px -8px 15px rgb(0, 0, 0, 0.75); */
  box-shadow: 1px 8px 15px rgb(0, 0, 0, 0.75);
  transition: box-shadow 0.3s ease;
  /* border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  overflow: hidden; */
}

button {
  width: 175px;
  padding: 0.8em 1.2em;
}
</style>
