<template>

    <div class="grid-table-cell-wide column top-margin">
        <div class="title-container" @click="toggleInstructions">
            <span class="arrow" :class="{ 'arrow-down': showInstructions }">&#9656;</span>
            <span class="centered-summary">{{ ui_string.question_new_instruct_title }}</span>
        </div>
    </div>
    <template v-if="showInstructions">
        <div class="grid-table-cell-wide column">
            <div class="label-text top-margin">{{ settings.formatString(ui_string.callee_instruct_title, { callee_name: callee_name }) }}</div>
            <textarea :placeholder="ui_string.callee_instruct_default" rows="5"
                v-model="callee.callee_instruct"></textarea>
        </div>
        <div class="grid-table-cell-wide column">
            <div class="label-text">{{ ui_string.family_instruct_title }}</div>
            <textarea :placeholder="ui_string.family_instruct_default" v-model="family_instruct" rows="5"></textarea>
        </div>
        <div class="grid-table-cell-narrow-button column">
            <button @click="instructUpdate">{{ui_string.question_new_instruct_button}}</button>
        </div>
        <div class="drop-shadow"></div>
    </template>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";
import axios from "axios";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";

const router = useRouter();
const settings = useSettingsStore();
const { BACK_URL, caller_id, callee_id, callee_name, caller_name, showModal, caller_instruct, family_instruct, family_id, ui_string } = storeToRefs(settings);
const callee = ref({});
const showInstructions = ref(false);

const toggleInstructions = () => {
    showInstructions.value = !showInstructions.value;
};

const calleeGetById = async () => {
    try {
        const payload = {
            callee_id: callee_id.value,
            caller_id: caller_id.value
        };
        const response = await axios.post(`${BACK_URL.value}/callee/callee_get_byid`, payload);
        callee.value = response.data;
    } catch (error) {
        console.error("Failed to fetch callee data:", error.response?.data?.detail[0]?.msg || error.message);
        // alert(`Failed to fetch callee data: ${error.response?.data?.detail[0]?.msg || error.message}`);
    }
};

const instructUpdate = async () => {
    showModal.value = true;
    try {
        const payload = {
            callee_id: callee_id.value,
            caller_id: caller_id.value,
            callee_instruct: callee.value.callee_instruct,
            caller_instruct: caller_instruct.value,
            family_instruct: family_instruct.value,
            family_id: family_id.value
        };
        await axios.put(`${BACK_URL.value}/instruct/instructs_all_update`, payload);
    } catch (error) {
        const errorMessage = error.response?.data?.detail || "Failed to update instructions.";
        console.error("Failed to update instructions:", errorMessage);
        alert(`Failed to update instructions: ${errorMessage}`);
    } finally {
        showModal.value = false;
    }
};

onMounted(async () => {
    await calleeGetById();
});

// Watch for changes in callee_id and update callee data
watch(callee_id, async () => {
    await calleeGetById();
});
</script>

<style scoped>
.title-container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.arrow {
    margin-right: 10px;
    transition: transform 0.3s ease;
    font-size: 20px; 
}

.arrow-down {
    transform: rotate(90deg);
}

.centered-summary {
    text-align: center;
    margin: 0;
}

/* .label-text {
    margin-bottom: 5px;
} */

/* textarea {
    width: 100%;
    margin-bottom: 15px;
} */

/* button {
    padding: 8px 16px;
    cursor: pointer;
} */

/* .grid-table-cell-wide {
    width: 100%;
    box-sizing: border-box;
} */

/* .column {
    display: flex;
    flex-direction: column;
} */
</style>
