<template>
    <div>
        <h2>SysAmd Manager</h2>
        <a href="https://www.twilio.com/docs/voice/answering-machine-detection" target="_blank"
            style="color: #007bff; text-decoration: none; font-weight: bold;">Twilio AMD Documentation</a>
        <div v-if="notification.show" :class="`notification ${notification.type}`" style="margin-top: 10px;">
            {{ notification.message }}
        </div>
        <div v-if="sysAmd">
            <h3>Current SysAmd</h3>
        </div>
        <div>
            <div class="form-group">
                <label for="amd_timeout">AMD Timeout:</label>
                <input v-model="sysAmdSettings.amd_timeout" id="amd_timeout" type="number" required />
            </div>
            <div class="form-group">
                <label for="amd_speech_threshold">AMD Speech Threshold:</label>
                <input v-model="sysAmdSettings.amd_speech_threshold" id="amd_speech_threshold" type="number" required />
            </div>
            <div class="form-group">
                <label for="amd_speech_end_threshold">AMD Speech End Threshold:</label>
                <input v-model="sysAmdSettings.amd_speech_end_threshold" id="amd_speech_end_threshold" type="number"
                    required />
            </div>
            <div class="form-group">
                <label for="amd_silence_timeout">AMD Silence Timeout:</label>
                <input v-model="sysAmdSettings.amd_silence_timeout" id="amd_silence_timeout" type="number" required />
            </div>
            <button class="btn" @click="saveSysAmd">Save</button>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useSettingsStore } from '../store';

const settings = useSettingsStore();

const sysAmd = ref(null);
const sysAmdSettings = ref({});
const notification = ref({ show: false, message: '', type: '' });

const fetchSysAmd = async () => {
    try {
        const response = await axios.get(`${settings.BACK_URL}/sys/sys_amd_getall`);
        sysAmd.value = response.data;

        sysAmdSettings.value = { ...response.data };
    } catch (error) {
        console.error('Failed to fetch SysAmd:', error);
    }
};


const saveSysAmd = async () => {
    try {
        const response = await axios.put(`${settings.BACK_URL}/sys/sys_amd_update`, sysAmdSettings.value);
        sysAmd.value = response.data;
        notification.value = { show: true, message: 'SysAmd saved successfully.', type: 'success' };
    } catch (error) {
        console.error('Failed to save SysAmd:', error);
        notification.value = { show: true, message: 'Failed to save SysAmd.', type: 'error' };
    }
    setTimeout(() => (notification.value.show = false), 3000);
};


onMounted(fetchSysAmd);
</script>

<style scoped>
.form-group {
    margin-bottom: 3rem;
}

label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: bold;
}

input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.btn {
    display: inline-block;
    padding: 0.5rem 1rem;
    margin-top: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.btn:hover {
    background-color: #0056b3;
}

.notification {
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
}

.notification.success {
    background-color: #d4edda;
    color: #155724;
}

.notification.error {
    background-color: #f8d7da;
    color: #721c24;
}
</style>
