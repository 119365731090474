<template>
  <Modal />
  <div class="grid-table-container">
    <div class="grid-table">
      <TopBar />
      <NavBar />
      <div class="bottom-margin"></div>

      <div>
        {{ settings.formatString(ui_string.answer_list_title_everyone, { 
          callee_name: callee_all ? 'Everyone' : callee_name, 
          caller_name: caller_all ? 'Everyone' : caller_name 
        }) }}
      </div>

      <template v-for="(call, index) in filteredAndIndexedCallList" :key="call.call_sid">
        <div v-if="call.answers.length > 0" class="grid-table-cell-wide row inlayed inlayed-top row-no-buttons"
          @click="() => navToViewCall(call.call_sid)">
          <div class="question-subject">
            <template v-if="caller_all || callee_all">
              {{ call.name_from }} to {{ call.name_to }}:
            </template>
            {{ call.question.question_subject }}
            <!-- <div class="question-datetime">{{ formatCallDate(call.date_created) }}</div> -->
            <div class="question-info">
              <span class="question-datetime">{{ formatCallDate(call.date_created) }}</span>
              <span class="question-duration">{{ formatDuration(call.answers[0].duration) }}</span>
            </div>
          </div>
        </div>
      </template>
      <div class="bottom-margin"></div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
import axios from "axios";
import NavBar from "./NavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";

const router = useRouter();
const settings = useSettingsStore();
const { showModal, caller_phone_number, timezone, BACK_URL, callee_phone_number, callee_id, caller_id, caller_all, callee_all, callee_name, caller_name, ui_string } = storeToRefs(settings);

const call_list = ref([]);

const timezoneMap = {
  5: "America/New_York",
  6: "America/Chicago",
  7: "America/Denver",
  8: "America/Los_Angeles"
};

const formatCallDate = (dateString) => {
  const timeZone = timezoneMap[timezone.value] || "UTC";
  const options = {
    timeZone,
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  };
  const date = new Date(dateString);
  return date.toLocaleString("en-US", options)
    .replace(",", "")
    .replace(" AM", "a")
    .replace(" PM", "p");
};

const formatDuration = (duration) => {
  const minutes = Math.floor(duration / 60);
  const seconds = (duration % 60) - 1;
  return `${minutes}:${Math.max(0, seconds).toString().padStart(2, '0')}`;
};

const formatPhoneNumber = (phoneNumber) => {
  // Assuming the format is always +1 followed by 10 digits
  const match = phoneNumber.match(/^\+1(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }
  return phoneNumber;
};

const filteredAndIndexedCallList = computed(() => {
  // Sort the filtered list by date_created from newest to oldest
  let sortedList = [...call_list.value].sort((a, b) => new Date(b.date_created) - new Date(a.date_created));

  // If caller_all or callee_all is true, sort by caller and callee
  if (caller_all.value || callee_all.value) {
    sortedList = sortedList.sort((a, b) => {
      if (a.name_from !== b.name_from) {
        return a.name_from.localeCompare(b.name_from);
      }
      return a.name_to.localeCompare(b.name_to);
    });
  }

  // Calculate total number of calls
  const totalCalls = sortedList.length;

  // Map each call to include a displayIndex
  return sortedList.map((call, index) => ({ 
    ...call, 
    displayIndex: totalCalls - index 
  }));
});

const call_get_bycalleeid = async () => {
  showModal.value = true;
  try {
    let url = `${BACK_URL.value}/call/call_get_bycalleeid`;
    const payload = {
      callee_id: callee_id.value,
      caller_id: caller_id.value,
      caller_all: caller_all.value,
      callee_all: callee_all.value
    };
    const response = await axios.post(url, payload);
    call_list.value = response.data;
    
    // Extract question IDs and store them in the global store
    const questionIds = call_list.value.map(call => call.question.id);
  } catch (error) {
    if (error.response) {
      console.error(`HTTP error! status: ${error.response.status}`);
    } else {
      console.error(`An error occurred: ${error}`);
    }
  } finally {
    showModal.value = false;
  }
};

const navToViewCall = (call_sid) => {
  router.push(`/call_history/${call_sid}`);
};

onMounted(async () => {
  await settings.fetchSettings();
  await call_get_bycalleeid();
  window.scrollTo(0, 0);
});

watch([callee_id, caller_all, callee_all], async () => {
  await call_get_bycalleeid();
});
</script>
<style scoped>
.question-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}

.question-datetime {
  color: #45818e;
  /* font-size: 0.9em; */
}

.question-duration {
  color: #45818e;
  /* font-size: 0.9em; */
}

.grid-table-cell-wide .question-subject {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}
</style>
