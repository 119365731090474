<template>
    <Modal />
    <div class="grid-table-container">
        <div class="grid-table">
            <TopBar />
            <div class="grid-table-cell-wide column">
                <img class="cassette-img cassette" src="/tape.png" />
            </div>
        </div>
    </div>
    <FamilyNavBar class="fixed-family-navbar" />
</template>

<script setup>
import { onMounted } from "vue";
import FamilyNavBar from "./FamilyNavBar.vue";
import TopBar from "./TopBar.vue";
import Modal from "./Modal.vue";
import { useSettingsStore } from "../store";
const settings = useSettingsStore();

onMounted(() => {
    window.scrollTo(0, 0);
    settings.fetchSettings();
    settings.fetchUIStrings();
});
</script>

<style scoped>
table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.cassette-img {
    margin-top: 80px;
    max-width: 85%;
    max-height: 85%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (orientation: landscape) {
  .cassette-img {
    /* display: none; */
  }
}

.fixed-family-navbar {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    
    width: 100%;
    max-width: 800px; 
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px;
    z-index: 1000; 
}
</style>
