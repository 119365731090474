<template>
  <!-- <div class="audio-recorder"> -->
  <!-- <div class="grid-table-cell-wide column"> -->
    <!-- </div> -->
    <div class="grid-table-cell-narrow-button column" style="line-height:0">
      <button @click="handleButtonClick" class="recording-button" :disabled="isCreatingAudio">
        <span v-if="isRecording" class="recording-indicator">●</span>
        {{ buttonText }}
      </button>
    </div>
    {{ ui_string.recording_instruct_title }}
  <!-- <audio v-if="questionRecordingUrl" :src="questionRecordingUrl" controls autoplay></audio> -->


  <label v-if="question_recording" class="use-recording-checkbox-container">
    <input type="checkbox" v-model="question_recording_only" class="use-recording-checkbox"
      :disabled="!question_recording || isCreatingAudio">
    <span>{{ ui_string.recording_only_button }}</span>
  </label>
  <!-- </div> -->
</template>

<script setup>
import { ref, computed } from 'vue';
import axios from 'axios';

import { useSettingsStore } from "../store";
import { storeToRefs } from 'pinia';
const settings = useSettingsStore();
const { BACK_URL, question_recording, question_recording_only, ui_string, isCreatingAudio } = storeToRefs(settings);

const isRecording = ref(false);
const mediaRecorder = ref(null);
const questionRecordingChunks = ref([]);
const questionRecordingUrl = ref(null);

const buttonText = computed(() => {
  if (isRecording.value) {
    return ui_string.value.recording_stop_button;
  } else if (question_recording.value) {
    return ui_string.value.recording_delete_button;
  } else {
    return ui_string.value.recording_add_button;
  }
});

const handleButtonClick = async () => {
  if (isRecording.value) {
    stopRecording();
  } else if (question_recording.value) {
    deleteRecording();
  } else {
    await startRecording();
  }
};

const startRecording = async () => {
  isRecording.value = true;
  questionRecordingChunks.value = [];

  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorder.value = new MediaRecorder(stream);

    mediaRecorder.value.ondataavailable = (event) => {
      if (event.data.size > 0) {
        questionRecordingChunks.value.push(event.data);
      }
    };

    mediaRecorder.value.onstop = () => {
      const audioBlob = new Blob(questionRecordingChunks.value, { type: 'audio/webm' });
      handleAudioFile(audioBlob);
    };

    mediaRecorder.value.start();
  } catch (error) {
    console.error('Error accessing microphone:', error);
    isRecording.value = false;
  }
};

const stopRecording = () => {
  isRecording.value = false;
  mediaRecorder.value.stop();
};

const deleteRecording = () => {
  question_recording.value = null;
  question_recording_only.value = false;
  questionRecordingUrl.value = null;
};

const handleAudioFile = async (audioBlob) => {
  questionRecordingUrl.value = URL.createObjectURL(audioBlob);
  console.log('Audio file:', audioBlob);

  // Create a FormData object to send the file
  const formData = new FormData();
  formData.append('file', audioBlob, 'question_recording.webm');

  try {
    const response = await axios.post(`${BACK_URL.value}/ai/question_recording_create`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    question_recording.value = response.data.question_audio_id;
    console.log('File uploaded successfully:', response.data);
  } catch (error) {
    console.error('Error uploading file:', error);
  }
};
</script>

<style scoped>
.audio-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
}


.recording-indicator {
  color: red;
  margin-right: 5px;
}

.use-recording-checkbox-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 10px;
}

.use-recording-checkbox {
  margin-right: 12px;
  appearance: none;
  -webkit-appearance: none;
  width: 22px;
  height: 22px;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  position: relative;
}

.use-recording-checkbox:checked::after {
  content: '\2714';
  font-size: 16px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.use-recording-checkbox:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}

.use-recording-checkbox-container span {
  color: #ffffff;
}

.use-recording-checkbox:disabled+span {
  color: #a0a0a0;
}

.recording-button {
  height: 55px;
}

.recording-button:disabled,
.use-recording-checkbox:disabled+span {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
