import { createRouter, createWebHistory } from "vue-router";
import { useSettingsStore } from "./store"; // Import the settings store

import Home from "./components/Home.vue";
import Family from "./components/Family.vue";
import FamilyJoin from "./components/FamilyJoin.vue";
import FamilyCreate from "./components/FamilyCreate.vue";
// import QuestionMake from "./components/QuestionMake.vue";
import QuestionList from "./components/QuestionList.vue";
import QuestionEdit from "./components/QuestionEdit.vue";
import CallHistory from "./components/CallHistory.vue";
import CallHistoryList from "./components/CallHistoryList.vue";
import Settings from "./components/Settings.vue";
import Caller from "./components/Caller.vue";
import CallerUpdate from "./components/CallerUpdate.vue";
import Callee from "./components/Callee.vue";
import CalleeUpdate from "./components/CalleeUpdate.vue";
import CalleeCreate from "./components/CalleeCreate.vue";
import CallerCreate from "./components/CallerCreate.vue";
import Dial from "./components/Dial.vue";
import SysConfig from "./components/SysConfig.vue";
import Reset from "./components/Reset.vue";

const routes = [
  { path: "/", component: Home },
  { path: "/home", component: Home },
  { path: "/family", component: Family },
  { path: "/family_join", component: FamilyJoin },
  { path: "/family_create", component: FamilyCreate },
  // { path: "/question_make", component: QuestionMake },
  { path: "/dial/:id", component: Dial },
  { path: "/question_list", component: QuestionList },
  { path: "/question_edit/:id", component: QuestionEdit },
  { path: "/question_edit", component: QuestionEdit },
  { path: "/call_history/:call_sid", component: CallHistory },
  { path: "/call_history/", component: CallHistory },
  { path: "/call_history_list", component: CallHistoryList },
  { path: "/settings", component: Settings },
  { path: "/caller", component: Caller },
  { path: "/caller_update/:caller_id", component: CallerUpdate },
  { path: "/callee", component: Callee },
  { path: "/callee_create", component: CalleeCreate },
  { path: "/caller_create", component: CallerCreate },
  { path: "/callee_update/:callee_id", component: CalleeUpdate },
  { path: "/sys_config", component: SysConfig },
  { path: "/reset", component: Reset },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/reset','/family','/family_create','/family_join']; 
  const authRequired = !publicPages.includes(to.path);

  // Function to get cookie by name
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const family_id = getCookie('family_id'); 

  if (authRequired && !family_id) {
    return next('/family_join');
  }

  next();
});


export default router;
