<template>
  <Modal />
  <div class="nav-bar">
    <div class="grid-table-cell-wide row nav-bar-top">
      <div>
        <button @click="loadCallers" :class="{ 'green-overlay': isCallerRoute }">{{ui_string.navbar_button_caller}}</button>
      </div>
      <!-- <div>
        <button @click="loadCallee" :class="{ 'green-overlay': isCalleeRoute }">Answer</button>
      </div> -->
      <div>
        <button @click="loadSettings" :class="{ 'green-overlay': isSettingsRoute }">Settings</button>
      </div>
      <!-- <div>
        <button @click="loadPromptConfig" >Config</button>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { storeToRefs } from 'pinia';
import { useSettingsStore } from "../store";
import Modal from "./Modal.vue";

const router = useRouter();
const route = useRoute();
const settings = useSettingsStore();
const { showModal, caller_phone_number, ui_string } = storeToRefs(settings);

const isCalleeRoute = computed(() => route.path === "/callee" || route.path.startsWith("/callee_update/"));
const isSettingsRoute = computed(() => route.path === "/settings");
const isCallerRoute = computed(() => route.path === "/caller");

const loadCallee = () => {
  showModal.value = true;
  router.push("/callee").finally(() => {
    showModal.value = false;
  });
};

const loadSettings = () => {
  showModal.value = true;
  router.push("/settings").finally(() => {
    showModal.value = false;
  });
};

const loadCallers = () => {
  showModal.value = true;
  router.push("/caller").finally(() => {
    showModal.value = false;
  });
};

const loadPromptConfig = () => {
  showModal.value = true;
  router.push("/sys_config").finally(() => {
    showModal.value = false;
  });
};
</script>

<style scoped>
.config-bar {
  display: flex;
  justify-content: space-around;
  padding: 10px;
  background-color: #f0f0f0;
}
button {
  width: 125px; 
  padding: 10px 20px;
  cursor: pointer;
}

</style>
