<template>
  <div v-if="showModal" class="modal">
    <div class="modal-content" style="display: flex; align-items: center; justify-content: space-between;">
      <p style="color: black">{{ui_string.modal_title}}</p>
      <span class="close" @click="showModal = false">&times;</span>
    </div>
  </div>
</template>

<script setup>
import { useSettingsStore } from '../store';
import { watch } from 'vue';

const settingsStore = useSettingsStore();
import { storeToRefs } from 'pinia';
const settings = useSettingsStore();
const { caller_id, caller_name, caller_phone_number, family_instruct, BACK_URL, ui_string } = storeToRefs(settings);

const { showModal } = storeToRefs(settingsStore);


</script>

<style scoped>
.close {
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.5s;
  margin-top: 25px;
}


.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 600px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: slideIn 0.5s, pulse 2s infinite;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-50px);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
</style>
