<template>
  <div>
    <p>Resetting...</p>
    <!-- <p>Local Storage before clearing: {{ preClearState }}</p>
    <p>Cookies before clearing: {{ preClearCookies }}</p>
    <p>Local Storage and Cookies have been cleared. Current state: {{ postClearState }}</p> -->
  </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useSettingsStore } from "../store";
import { useRouter } from "vue-router";

const preClearState = ref('');
const preClearCookies = ref('');
const postClearState = ref('');

const settingsStore = useSettingsStore();
const router = useRouter();

onMounted(() => {
  preClearState.value = JSON.stringify({...localStorage});
  preClearCookies.value = document.cookie;
  settingsStore.logout();
  settingsStore.$reset(); 
  
  // Clear local storage and cookies
  localStorage.clear();
  document.cookie.split(";").forEach((c) => {
    document.cookie = c
      .replace(/^ +/, "")
      .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
  });

  postClearState.value = JSON.stringify({...localStorage});
  
  // Add a slight delay before redirecting to ensure all clearing operations are complete
  setTimeout(() => {
    router.push('/login');
  }, 1000);
});
</script>
